@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "https://use.typekit.net/xen1fxp.css";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";

@import "@clear-treasury/design-system/dist/styles.min.css";

.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 20s linear forwards;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@layer utilities {
  .clip-rect-bar {
    clip: rect(0px, 1.5rem, 1.5rem, 0.75rem);
  }

  .clip-rect-progress {
    clip: rect(0px, 0.75rem, 1.5rem, 0px);
  }
}

.circle .right .progress {
  animation-delay: 10s;
}

.ag-theme-material .ag-header {
  background-color: #f7f7f7;
}

.ag-theme-material .ag-header {
  height: 48px !important;
  min-height: 48px !important;
  border: none;
}

.ag-theme-material .ag-header-row {
  height: 48px !important;
}

.ag-theme-material .ag-center-cols-container {
  background-color: #f7f7f7;
}

.ag-theme-material .ag-center-cols-container > div {
  border: none;
}

/* .errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
}

.logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.errorTitle {
  font-size: 2rem;
  color: #ff6347;
}

.errorMessage {
  font-size: 1.2rem;
  margin: 20px 0;
}

.backButton {
  font-size: 1rem;
  padding: 10px 20px;
  color: #0070f3;
  background: none;
  border: 2px solid #0070f3;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.backButton:hover {
  background-color: #0070f3;
  color: #fff;
} */
